import {Titolo} from "./Titolo";
import Step from '../assets/la-tua-certificazione.png';
import {GoInfo} from "react-icons/go";
import {Poppover} from "./Poppover";

export const TerzoBlocco = () => {
    // const [show, setShow] = useState(false);
    // const target = useRef(null);

    return (
        <div className="row">
            <Titolo title="La tua certificazione EPD in 5 semplici step" class="h3generico martop-70"></Titolo>
            <div className="col-xs-12 col-md-5 col-lg-5 text-center  martop-40 m-top-15">
                <img src={Step} className="img-fluid rounded" alt="Certificazione in 5 step"/>
            </div>
            <div className="col-xs-12 col-md-7 col-lg-7  martop-40 m-top-15">
                <p>Calcola l’impatto ambientale dei tuoi prodotti e ottieni la certificazione
                    evitando inutili perdite di tempo e di denaro.
                </p>
                   <ul className="ulinterno" id={'info'}>

                    <li>Verifichiamo la presenza delle <Poppover title={"PCR (Product Category Rules)"}
                                  contenuto={"Le Product Category Rules (PCR) - o Regole di Categoria\n" +
                                      "                              di Prodotto - sono regole che definiscono le\n" +
                                      "                              caratteristiche tecniche e funzionali di una\n" +
                                      "                               categoria di prodotti. Prima di procedere alla\n" +
                                      "                                redazione dell’EPD bisogna verificare se esistono\n" +
                                      "                                le PCR per il tipo di prodotto/servizio che si vuole certificare."}></Poppover>
                    </li>
                    <li>Procediamo con lo studio <Poppover title={"LCA (Life Cycle Assessment)"}
                                                           contenuto={"La LCA (Life Cycle Assessment) è la metodologia che permette di rilevare l’impatto che il \n" +
                                                               "                        prodotto ha sull’ambiente in ogni fase del suo ciclo di vita (pre-produzione, produzione, distribuzione, trasporto, uso, \n" +
                                                               "                        riciclo e smaltimento finale). Questo studio deve essere svolto nel rispetto delle modalità indicate dalla norma ISO 14040."}></Poppover>
                    </li>
                    <li>Redigiamo la  <Poppover title={"Dichiarazione Ambientale di Prodotto"}
                                                             contenuto={"La Dichiarazione Ambientale di Prodotto (EPD - Environmental Product Declaration) -\n" +
                                                                 "                         è un’etichetta ambientale di III tipo; una dichiarazione inclusa tra le politiche ambientali comunitarie\n" +
                                                                 "                          che permette di attestare l’impatto ambientale che il prodotto/servizio ha durante ogni fase del suo ciclo di vita. \n" +
                                                                 "                          É sviluppata secondo norme internazionali che la rendono una dichiarazione chiara, trasparente e oggettiva."}></Poppover>

                    </li>
                    <li>Stiliamo un report sullo studio LCA effettuato
                    </li>
                    <li>Ti assistiamo fino alla procedura di verifica e al conseguimento della certificazione EPD
                    </li>
                </ul>
                <p className="fw-bold fst-italic">Dimostra la sostenibilità dei tuoi prodotti e comunica agli
                    stakeholder
                    l’impegno ambientale della tua azienda.
                </p>

            </div>
        </div>
    )
}
