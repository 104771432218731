import Cam from '../assets/Cosa-sono-i-cam.png'


export const CamBlocco = () => {
    return (
        <div className="row mt-5">
            <h2 className={'fw-bolder text-center mb-5'}>Cosa sono i CAM</h2>
            <div className="col-xs-12 col-md-5 col-lg-5 text-center">
                <img src={Cam}  className="img-fluid rounded" alt="Cosa sono i CAM"/>
            </div>
            <div className="col-xs-12 col-md-7 col-lg-7 m-top-15">
                <h2 className="fw-bolder tecnoOrange">Standard ambientali per lo sviluppo sostenibile
                </h2>
                <p>Anche la Pubblica Amministrazione deve indirizzare le sue scelte di acquisto verso soluzioni a basso
                    impatto ambientale, che consentano di razionalizzare i consumi e limitare le spese della PA. Per
                    questa ragione, con l’art. 34 del D. Lgs. 50/2016, viene introdotto l’obbligo di inserire i CAM
                    nelle gare di appalto pubbliche.</p>
                <p>I CAM sono documenti che individuano le <strong>caratteristiche che il progetto, il prodotto o il servizio
                    deve possedere per poter accedere alla selezione dell’acquisto verde.</strong> Forniscono dettagli circa
                    specifiche tecniche, clausole contrattuali e criteri premianti. Sono definiti dal PAN GPP (Piano per
                    la sostenibilità ambientale dei consumi del settore della pubblica amministrazione) e adottati con
                    decreto del Ministro dell’Ambiente.
                </p>

            </div>
        </div>

    );
}

