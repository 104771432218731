import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Carosello} from "../components/Carosello";
import  '../blog.css';
import {Forms} from "../components/Forms";
import { Helmet } from "react-helmet";


export const News = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState();
    const [items, setItems] = useState([]);
    const [titolo, setTitolo] = useState([]);
    const [url, setUrl] = useState([]);
    const variabile = useParams();
    const handleDragStart = (e) => e.preventDefault();

    // Nota: l'array deps vuoto [] significa
    // questo useEffect verrà eseguito una volta
    // simile a componentDidMount()

    useEffect(() => {
        window.scrollTo(0, 0)
        fetch("https://gestione.tecnocompany.com/api/show-news.php", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                site: 'certificazioneepd',
                category: 26,
                url_rewrite: variabile.blog
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                    setTitolo(result[0])
                    setUrl(variabile.blog)
                    
                },
                // Nota: è importante gestire gli errori qui
                // invece di un blocco catch() in modo da non fare passare
                // eccezioni da bug reali nei componenti.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [variabile.blog])

    if (error) {
        return <div>Error: {error.message}</div>;
    }else if (!isLoaded) {
        return <div>Loading...</div>;
    }  else {
        document.title = titolo.titolo;

        document.getElementsByTagName("meta")[3].content=titolo.titolo;
        document.getElementsByTagName("meta")[4].content=titolo.parola_chiave;

        return (
            <div>
             <Helmet>
                <link rel="canonical" href={"https://www.certificazioneepd.it/news/"+url} />
             </Helmet>
            <section className={'container'}>
                <div className={'row'}>
                    {items.map(item => (
                        <div key={item.titolo}>
                            <h1 className={'text-center mb-5 martop-50  fw-bolder'}>{item.titolo}</h1>
                            <div id={'contenutoBlog'} dangerouslySetInnerHTML={{__html: item.descrizione}} />
                        </div>
                        ))}

                </div>
            </section>


                <Forms title={'Scrivici'}></Forms>
                <section className={'container'}>
                    <div className={'row'}>
                        {items.map(item => (
                            <div key={item.titolo}>
                                {item.nominativoAutore ? (
                                    <div className={'card'}>
                                        <div className={'row'}>
                                            <div className={'col-xs-12 col-md-2 col-lg-2'}>
                                                <img src={item.avatarAutore} className={'m-1 p-1'}/>
                                            </div>
                                            <div className={'col-xs-12 col-md-10 col-lg-10'}>
                                                <h5 className={'text-left mb-1 fw-bolder'}>{item.nominativoAutore}</h5>
                                                <div id={'contenutoBlog'} dangerouslySetInnerHTML={{__html:item.bioAutore}} />
                                            </div>
                                        </div>
                                    </div>
                                ):('')
                                }
                            </div>
                        ))}

                    </div>
                </section>


                <section className={'container-fluid carosolle martop-50 m-top-15'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-xs-12 col-md-12 col-lg-12'}>

                                <Carosello>

                                </Carosello>
                            </div>
                        </div>

                    </div>

                </section>
            </div>


        );
    }
}
