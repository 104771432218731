import React, { useEffect, useState } from "react";
import {ColonnaTre} from "../components/ColonnaTre";
export const Blog = () =>{
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    // Nota: l'array deps vuoto [] significa
    // questo useEffect verrà eseguito una volta
    // simile a componentDidMount()
    useEffect(() => {
        fetch("https://gestione.tecnocompany.com/api/list-news.php", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                site: 'certificazioneepd',
                category: 26,
                last: ""
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {

        return (
            <section className={'container m-top-15'}>
                <div className={'row'}>

                        {items.map(item => (
                            <ColonnaTre key={item.titolo} title={item.titolo} sottotitolo={item.sottotitolo} image={item.img}  url={item.url} ></ColonnaTre>

                        ))}

                </div>
            </section>

        );
    }
}