import React from 'react';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import {GoInfo} from "react-icons/go";
export const Poppover = (props) => {
    let ref = React.useRef(null);
    const popoverLeft  = (
        <Popover  id="popover-trigger-click-root-close"  style={{
            backgroundColor: '#4c832f',
            padding: '10px 10px',
            color: 'white',
            borderRadius: 3,
        }}>{props.contenuto}
        </Popover>
    );
    return (
         <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverLeft} >
            <span className={'infopop'}>{props.title} <GoInfo/></span>

        </OverlayTrigger>

    )
}
