import {NavLink} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import logo from '../logo.svg';
import './Header.css';

export const Header = () => {
    const handleCollapse  = () => {
        var nav = document.getElementById("navbarNavDropdown");
        var btn = document.getElementsByClassName("navbar-toggler");
        nav.classList.remove("show");

    };
    return (
        <header>
            <section className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-light ">
                        <NavLink className="navbar-brand" to="/"><img src={logo}
                                                                     className="logoHome" alt="Tecno srl"/></NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <NavLink activeClassName={'active'} className="nav-link" exact to="/" onClick={handleCollapse}>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName={'active'} className="nav-link" to="/cam" onClick={handleCollapse}>Cam</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName={'active'} className="nav-link" to="/news" onClick={handleCollapse}>News</NavLink>
                                </li>

                                <li className="nav-item">
                                    <HashLink  className="nav-link mobilecontact" id="contattaci" to="/#contatti" onClick={handleCollapse}>CONTATTACI</HashLink>
                                </li>
                            </ul>

                        </div>
                    </nav>
                </div>
            </section>
        </header>
    );
}

