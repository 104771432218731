import { HashLink } from "react-router-hash-link";
export const SecondoBloccoPlus = () => {
  return (
    <div className="row">
      <div className="col-xs-12 col-lg-12 ">
        <div
          className="card personalcard"
          data-aos="fade-down"
          style={{
            minHeight: 150,
          }}
        >
          <div className="card-body">
            <h3
              className="card-title  fw-bold text-center fw-bolder text-center h3generico "
              style={{
                minHeight: 5,
              }}
            >
              Dimostra la conformità ai CAM dei tuoi prodotti con noi
            </h3>
            <p className="card-text text-center">
              La certificazione EPD, insieme ad altre certificazioni ambientali
              (es. <a href=" https://productcarbonfootprint.it" target="_blank" style={{color:'#2c6e01'}}>certificazione carbon footprint</a> di prodotto), ti permette di
              dimostrare che il prodotto possiede le caratteristiche
              ambientali definite nei CAM (Criteri Ambientali Minimi) e che
              pertanto rispetta gli standard di sostenibilità previsti dalle
              gare di appalto pubbliche.
            </p>
            <p className="card-text text-center">
              Per ogni settore merceologico la Pubblica Amministrazione richiede
              la conformità ai CAM.
            </p>
            <p className="card-text text-center">
              <HashLink to="cam#root">
                <button className="btn buttonTecno">SCOPRI DI PIÙ</button>
              </HashLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
