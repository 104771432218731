import Button from "./Buttons";
import { HashLink } from "react-router-hash-link";
export const SecondoBlocco = () => {
  return (
    <div className="row">
      <div className="col-xs-12 col-md-3 col-lg-3">
        <div className="card personalcard" data-aos="fade-down">
          <div className="card-body">
            <h3 className="card-title titleH3 fw-bold text-uppercase text-center">
              Con l’EPD nessuna esclusione dagli appalti verdi
            </h3>

            <p className="card-text text-center">
              Con la certificazione EPD dimostri che i tuoi prodotti sono
              conformi ai CAM (Criteri Ambientali Minimi) e quindi dotati delle
              caratteristiche tecniche necessarie per partecipare alle gare di
              appalto/bandi indetti dalla Pubblica Amministrazione.
            </p>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-3 col-lg-3" data-aos="fade-down">
        <div className="card personalcard">
          <div className="card-body">
            <h3 className="card-title titleH3 fw-bold text-uppercase text-center">
              La certificazione EPD ti aiuta a vendere su Amazon
            </h3>

            <p className="card-text text-center">
              Le piattaforme di shopping online, come Amazon o Slow Nature,
              selezionano i produttori/venditori attraverso criteri di
              sostenibilità molto rigorosi, premiando chi offre beni a basso
              impatto ambientale.
              <br />
              Con la certificazione EPD superi i test di sostenibilità e accedi
              ai canali di vendita digitali.
            </p>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-3 col-lg-3" data-aos="fade-down">
        <div className="card personalcard">
          <div className="card-body">
            <h3 className="card-title titleH3 fw-bold text-uppercase text-center">
              Accedi ai bonus statali e agli appalti per l’edilizia
            </h3>

            <p className="card-text text-center">
              Con la certificazione EPD attesti la percentuale di materia
              riciclata dei materiali impiegati per costruzioni e lavorazioni
              edili, fondamentale per accedere ad alcuni bonus statali (es.
              superbonus 110%). Inoltre dimostri che i tuoi prodotti sono
              conformi ai CAM Edilizia.
            </p>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-3 col-lg-3" data-aos="fade-down">
        <div className="card personalcard">
          <div className="card-body">
            <h3 className="card-title titleH3 fw-bold text-uppercase text-center">
              Con l’EPD dimostri che la tua azienda è attenta all’ambiente
            </h3>

            <p className="card-text text-center">
              Esponi il logo EPD sui tuoi prodotti certificati. In questo modo
              rafforzi l’immagine aziendale, aiuti potenziali clienti e
              fornitori a calcolare l’impatto ambientale dei beni (b2b e b2c) e
              conquisti chi è in cerca di prodotti sostenibili.
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};
